import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CAnchorNav,
  CHeroImg,
  AssetImage,
  CAccessMedia,
  CSimpleAccordionList,
  CContactBox,
  CBtnList,
  CDefinition,
  CTab,
  CTabItem,
  LPlan,
} from '../../components/_index';
import { setLang } from '../../utils/set-lang';
import '../../assets/_sass/page/access.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const lang = setLang();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'access',
            ja: 'アクセス',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/access/kv.png',
              },
              imgSp: {
                src: '/assets/images/access/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'アクセス',
            },
          }}
        />
      </CJumbotron>
      <div className="p_access">
        <section className="l_sect02">
          <LWrap>
            <CAccessMedia
              data={[
                {
                  logo: {
                    src: '/assets/images/common/logo_color.svg',
                    alt: 'SENDAI ROYAL PARK HOTEL',
                  },
                  text: (
                    <>
                      <p className="title">仙台ロイヤルパークホテル</p>
                      <p>
                        〒981-3204
                        <br />
                        仙台市泉区寺岡6-2-1
                      </p>
                      <p>
                        <a href="TEL:0223771111">TEL:022-377-1111（代表）</a>
                      </p>
                    </>
                  ),
                  btnList: [
                    {
                      label: 'Google MAP',
                      link: {
                        href: 'https://www.google.com/maps?ll=38.342863,140.837966&z=16&t=m&hl=ja&gl=JP&mapclient=embed&cid=12978498071859459586',
                        blank: true,
                      },
                      icon: 'blank',
                      color: 'borderTheme',
                    },
                  ],
                },
              ]}
            />
          </LWrap>
        </section>
        <div className="p_anchorNavWrapper">
          <LWrap>
            <CAnchorNav
              data={[
                {
                  link: {
                    href: '/access#car',
                  },
                  label: '車でお越しのお客様',
                },
                {
                  link: {
                    href: '/access#subway',
                  },
                  label: (
                    <>
                      バス・地下鉄で
                      <br />
                      お越しのお客様
                    </>
                  ),
                },
                {
                  link: {
                    href: '/access#bus',
                  },
                  label: (
                    <>
                      無料シャトルバスを
                      <br />
                      ご利用のお客様
                    </>
                  ),
                },
                {
                  link: {
                    href: '/access#kaeru',
                  },
                  label: 'かえるタクシーサービス',
                },
              ]}
            />
          </LWrap>
        </div>
        <section className="u_mb100_pc">
          <LWrap exClass="u_bgGray p_accessWrapper">
            <h2 className="c_headingLv2 u_tal" id="car">
              車でお越しのお客様
            </h2>
            <ul className="c_circleList">
              <li>地下鉄泉中央駅から約15分</li>
              <li>JR仙台駅から約30分</li>
              <li>仙台空港から約40分（仙台東部道路、東北自動車道利用）</li>
              <li>東北自動車道「泉IC」から約10分</li>
              <li>東北自動車道「泉PAスマートIC（ETC専用IC）」から約5分</li>
            </ul>
            <ul className="c_noteList indent u_mb55">
              <li>渋滞が少なく、泉ICよりスムーズです。</li>
            </ul>
            <h3 className="c_headingLv3 u_mb20">無料駐車場のご案内</h3>
            <CHeroImg
              img={{
                src: '/assets/images/access/img_carmap.png',
                alt: '無料駐車場のご案内',
              }}
            />
            <p className="p_lead">
              ホテル駐車場（平面）：35台（うち車いす使用者優先スペース3台）
            </p>
            <p className="p_lead">
              ホテル駐車場（地下）：71台（うちEV・PHV用充電器対応4台、テスラ専用超急速充電器「スーパーチャージャーステーション」対応4台）
            </p>
            <p className="p_lead u_mb40">
              臨時駐車場（タピオ2階）：114台（通常時クローズ。ホテル駐車場が満車時のみご案内いたします）
            </p>
            <CSimpleAccordionList
              data={[
                {
                  title: (
                    <>
                      テスラ専用超急速充電器
                      <br className="u_sp" />
                      ご利用について
                    </>
                  ),
                  content: (
                    <>
                      <div className="imgWrapper">
                        <AssetImage
                          src="/assets/images/access/img_tesla.png"
                          alt=""
                        ></AssetImage>
                      </div>
                      <p className="p_lead u_mb30">
                        ホテル駐車場（地下）に4台設置しているテスラ専用超急速充電器「スーパーチャージャーステーション」は、ホテルをご利用されないお客様もご利用いただくことができます。「スーパーチャージャーステーション」に空きがない場合は、ホテルスタッフにお声掛けください。スーパーチャージャーに関してご不明な点は、下記までお問い合わせください。
                      </p>
                      <div className="c_contactBox">
                        <div className="colUnit">
                          <p className="title">
                            テスラジャパン ロードサイドサポート
                          </p>
                          <p className="tel">
                            <i className="icon-tel"></i>
                            <a href="tel:0120975214">0120-975-214</a>
                          </p>
                        </div>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_mb100_pc">
          <LWrap exClass="p_spBgWhite p_accessWrapper">
            <h2 className="c_headingLv2 u_tal" id="subway">
              バス・地下鉄でお越しのお客様
            </h2>
            <div className="p_use_subway">
              <h3 className="c_headingLv3 u_mb25">
                地下鉄＋宮城交通バスをご利用の場合
              </h3>
              <figure className="imgWrapper u_pc">
                <AssetImage
                  src="/assets/images/access/img_use_subway.png"
                  alt=""
                ></AssetImage>
              </figure>
              <figure className="imgWrapper u_sp">
                <AssetImage
                  src="/assets/images/access/img_use_subway__sp.png"
                  alt=""
                ></AssetImage>
              </figure>
            </div>
            <div className="p_use_bus">
              <h3 className="c_headingLv3 u_mb25">
                宮城交通バスをご利用の場合
              </h3>
              <figure className="imgWrapper u_pc">
                <AssetImage
                  src="/assets/images/access/img_use_bus.png"
                  alt=""
                ></AssetImage>
              </figure>
              <figure className="imgWrapper u_sp">
                <AssetImage
                  src="/assets/images/access/img_use_bus__sp.png"
                  alt=""
                ></AssetImage>
              </figure>
            </div>
            <div className="p_businfo">
              <h3 className="c_headingLv3 u_mb25">バス時刻表・運賃のご案内</h3>
              <p className="p_lead02">
                宮城交通バスのウェブサイトでご確認ください。
              </p>
              <ul className="c_noteList">
                <li>
                  「路線バス時刻・運賃案内」の［出発地停留所］［到着地停留所］に、「仙台駅前」「地下鉄泉中央駅」「寺岡一丁目北・泉アウトレット」「寺岡六丁目・泉アウトレット」などのバス停名を入れて検索してください。
                </li>
              </ul>
              <CBtnList
                exClass="en_large_btn"
                align="left"
                data={[
                  {
                    label: '宮城交通バス時刻・運賃案内',
                    link: {
                      href: 'https://transfer.navitime.biz/miyagikotsu/pc/map/Top',
                      blank: true,
                    },
                    color: 'borderTheme',
                    icon: 'blank',
                  },
                ]}
              />
            </div>
          </LWrap>
        </section>

        <section className="u_mb100_pc">
          <LWrap exClass="u_bgGray p_accessWrapper">
            <h2 className="c_headingLv3 u_mb25" id="bus">
              無料シャトルバスをご利用のお客様
            </h2>
            <p className="p_lead02">
              無料シャトルバスの時刻表は下記のPDFよりご確認ください。
            </p>
            <CBtnList
              exClass="u_mt15 u_mb25"
              align="left"
              data={[
                {
                  label: '無料シャトルバスの時刻表',
                  link: {
                    href: '/assets/files/pdf/20211102shuttlebus.pdf',
                    blank: true,
                  },
                  color: 'borderTheme',
                  icon: 'arrow',
                },
              ]}
            />
            <ul className="c_noteList u_mb55">
              <li>
                ホテル専用のシャトルバス（ホテルロゴマークが目印）は無料・事前予約不要でご利用いただけます。
              </li>
              <li>
                バスのご乗車はホテルご利用のお客様に限らせていただきます。
              </li>
              <li>
                シャトルバスは小型バスとなります。座席には限りがございますのであらかじめご了承ください。
              </li>
              <li>
                天候や交通渋滞により、発着時刻が遅れる場合がございますのでご了承ください。
              </li>
              <li>
                車両点検等により、ラッピングバスではない車両で運行する場合がございます。
              </li>
            </ul>
            <h3 className="c_headingLv3 u_mb20">
              仙台駅（東口）から無料シャトルバスのりばまでのご案内
            </h3>
            <CHeroImg
              img={{
                src: '/assets/images/access/img_busmap.png',
                alt: '仙台駅（東口）から無料シャトルバスのりばまでのご案内',
              }}
            />
            <div className="u_pc">
              <CTab defaultKey={0}>
                <CTabItem
                  title="仙台駅東口 「観光・送迎バスのりば」"
                  tabKey={0}
                >
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      さらに直進すると、「観光・送迎バスのりば」に通じるエレベーター乗降口が見えてきます。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route02.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      「観光・送迎バスのりば」に通じるエレベーター乗降口の少し手前にもエスカレーターと階段がありますが、ここから降りても「観光・送迎バスのりば」に行くことはできません。ご注意ください。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route03.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      エレベーター乗降口の後方にあるエスカレーターと階段からは「観光・送迎バスのりば」に行くことができます。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route04.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      エレベーターを降りると、すぐ目の前が「観光・送迎バスのりば」です。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route05.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      ホテル専用無料シャトルバスの停車スペースは都度異なります。また、バスが画像と異なる場合もございます。ご利用時にご注意ください。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route06.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      エスカレーター・階段で降りる場合は、進行方向左側（画像右側の斜線部分）が「観光・送迎バスのりば」です。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route07.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      ホテル専用無料シャトルバスの停車スペースは都度異なります。また、バスが画像と異なる場合もございます。ご利用時にご注意ください。
                    </p>
                  </div>
                </CTabItem>
                <CTabItem
                  title="「新幹線改札」からの お客様はこちら"
                  tabKey={1}
                >
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route2.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      画像の場所は仙台駅３階にある新幹線南口改札前、みどりの窓口付近です。ここから矢印方向にお進みいただき、突き当たりのレストランの前を右折してください。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route2_02.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      レストランの前を右折すると、下りのエスカレーターが見えてきます。このエスカレーターで２階に下ります。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route2_03.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      エスカレーターを下りたら、斜線の通路を左方向に進みます。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route2_04.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      エスカレーターから下りた場所を別角度から見た画像です。エスカレーターを下りると緑色の矢印のところに出ます。斜線の通路を矢印方向に進んでください。
                    </p>
                  </div>
                </CTabItem>
                <CTabItem
                  title="「仙台駅西口」からの お客様はこちら"
                  tabKey={2}
                >
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route3.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      画像の場所は仙台駅西口のペデストリアンデッキです。矢印の2F入口から仙台駅に入った方が、そのまま東西自由通路を直進するだけなので分かりやすいです。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route3_02.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      頭上の案内表示の通り、東口バスプール（観光・送迎バスのりば）方面に進みます。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route3_03.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      東西自由通路は長さがあるため通り抜けるのに少々時間がかかります。そのまま直進してください。
                    </p>
                  </div>
                  <div className="p_route">
                    <div className="imgWrapper">
                      <AssetImage
                        src="/assets/images/access/img_route3_04.png"
                        alt=""
                      ></AssetImage>
                    </div>
                    <p className="p_routeLead">
                      吹き抜けのエリアになると、ようやく外が見えてきます。そのまま直進してください。
                    </p>
                  </div>
                </CTabItem>
              </CTab>
            </div>
            <div className="u_sp">
              <CSimpleAccordionList
                data={[
                  {
                    title: <>仙台駅東口 「観光・送迎バスのりば」</>,
                    content: (
                      <>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            さらに直進すると、「観光・送迎バスのりば」に通じるエレベーター乗降口が見えてきます。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route02.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            「観光・送迎バスのりば」に通じるエレベーター乗降口の少し手前にもエスカレーターと階段がありますが、ここから降りても「観光・送迎バスのりば」に行くことはできません。ご注意ください。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route03.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            エレベーター乗降口の後方にあるエスカレーターと階段からは「観光・送迎バスのりば」に行くことができます。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route04.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            エレベーターを降りると、すぐ目の前が「観光・送迎バスのりば」です。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route05.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            ホテル専用無料シャトルバスの停車スペースは都度異なります。また、バスが画像と異なる場合もございます。ご利用時にご注意ください。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route06.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            エスカレーター・階段で降りる場合は、進行方向左側（画像右側の斜線部分）が「観光・送迎バスのりば」です。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route07.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            ホテル専用無料シャトルバスの停車スペースは都度異なります。また、バスが画像と異なる場合もございます。ご利用時にご注意ください。
                          </p>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: <>「新幹線改札」からの お客様はこちら</>,
                    content: (
                      <>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route2.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            画像の場所は仙台駅３階にある新幹線南口改札前、みどりの窓口付近です。ここから矢印方向にお進みいただき、突き当たりのレストランの前を右折してください。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route2_02.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            レストランの前を右折すると、下りのエスカレーターが見えてきます。このエスカレーターで２階に下ります。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route2_03.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            エスカレーターを下りたら、斜線の通路を左方向に進みます。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route2_04.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            エスカレーターから下りた場所を別角度から見た画像です。エスカレーターを下りると緑色の矢印のところに出ます。斜線の通路を矢印方向に進んでください。
                          </p>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: <>「仙台駅西口」からの お客様はこちら</>,
                    content: (
                      <>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route3.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            画像の場所は仙台駅西口のペデストリアンデッキです。矢印の2F入口から仙台駅に入った方が、そのまま東西自由通路を直進するだけなので分かりやすいです。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route3_02.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            頭上の案内表示の通り、東口バスプール（観光・送迎バスのりば）方面に進みます。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route3_03.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            東西自由通路は長さがあるため通り抜けるのに少々時間がかかります。そのまま直進してください。
                          </p>
                        </div>
                        <div className="p_route">
                          <div className="imgWrapper">
                            <AssetImage
                              src="/assets/images/access/img_route3_04.png"
                              alt=""
                            ></AssetImage>
                          </div>
                          <p className="p_routeLead">
                            吹き抜けのエリアになると、ようやく外が見えてきます。そのまま直進してください。
                          </p>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </LWrap>
        </section>

        <section className="">
          <LWrap exClass="p_spBgWhite p_accessWrapper">
            <h2 className="c_headingLv3 u_mb25" id="kaeru">
              <small>泉パークタウン在住の方限定</small>
              <br />
              かえるタクシーサービス
            </h2>
            <p className="p_lead u_mb30">
              泉パークタウンにお住いの方限定で、ご自宅よりタクシーにてホテルへご来館いただいた場合、
              <br />
              お帰りの際の初乗り料金を割引いたします。
            </p>
            <CDefinition
              exClass="u_mb25"
              data={[
                {
                  title: <>対象地区</>,
                  text: <>寺岡・紫山・高森・桂・北高森・明通</>,
                },
                {
                  title: <>割引額</>,
                  text: <>お帰りの際の初乗り料金を710円割引</>,
                },
                {
                  title: <>対象タクシー</>,
                  text: <>仙台中央タクシー</>,
                },
              ]}
            />
            <ul className="c_noteList">
              <li>
                ご自宅からホテルまでは仙台中央タクシーをご予約のうえお越しください。仙台中央タクシー問い合わせ先：0570-057-818（ナビダイヤル）
              </li>
              <li>仙台中央タクシー以外のタクシー会社は対象外となります。</li>
              <li>
                ホテル到着時にタクシー乗務員より「かえるタクシー券」をお受け取りください。
              </li>
              <li>
                「かえるタクシー券」は各店舗にてお会計時にご提示ください。
              </li>
              <li>
                お帰りのタクシーはフロントにて仙台中央タクシーを手配いたします。
              </li>
              <li>
                「泉パークタウン　タピオ」をご利用の場合も対象となります。
              </li>
            </ul>
          </LWrap>
        </section>
      </div>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
